import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Contact from "../components/Contact";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";
import Blockquote from "../components/Blockquote";

const ContactPage = props => {
  const {
    data: {
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline title="Talk to Us" theme={theme} />
            </header>
            <p>Are you an extremely interesting woman with a question or comment? </p>
            <p>We want to hear from you!</p>
            <p> Send us a note here: </p>
            <Contact theme={theme} />
            <Blockquote
              title=""
              content="Once we recognize what it is we are feeling, once we recognize we can feel deeply, love deeply, can feel joy, 
              then we will demand that all parts of our lives produce that kind of joy."
              author="Audre Lorde"
              theme={theme}
            />{" "}
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook} />
      <style>
        {`
          
        `}
      </style>
    </React.Fragment>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContactPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query ContactQuery {
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
