import React from "react";
import PropTypes from "prop-types";

const Blockquote = props => {
  const { title, author, content, theme } = props;

  return (
    <React.Fragment>
      <blockquote>
        <h3>{title}</h3>
        <br />
        <p>{content}</p>
        <br />
        <p>{`~  ${author}`}</p>
      </blockquote>
      {/* --- STYLES --- */}
      <style jsx>{`
        blockquote {
          color: ${theme.color.brand.darkActive};
          background-color: ${theme.color.neutral.gray.c};
          padding: ${theme.space.s} ${theme.space.l};
          border: solid ${theme.space.s} ${theme.color.brand.dark};
          border-color: rgba(0, 0, 0, 0.5);
          max-width: 700px;
          margin: auto;
          margin-top: ${theme.space.m};
        }
      `}</style>
    </React.Fragment>
  );
};

Blockquote.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  content: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Blockquote;
